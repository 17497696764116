import {
  faInstagram,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import NextLink from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import { assetDomain } from "../../../config/config";
import { getLocaleCopies } from "../../../helpers/locale_management";
import {
  ColumnContainer,
  ColumnContainerMiddle,
  ColumnLink,
  CompanyTitleStyled,
  ContainerStyled,
  ContentContainer,
  CopyrightContainer,
  CopyrightContainerMiddle,
  CopyrightText,
  FirstContentContainer,
  GridIcons,
  GridIconsContainer,
  GridLinks,
  GridLinksContainer,
  GridMission,
  IconStyled,
  LinkIcon,
  LinkText,
  MissionText,
  VerticalSeparator,
} from "./styles";

export default function SharedFooter() {
  const copies = getLocaleCopies();

  let year = new Date().getFullYear();

  const [hasMounted, setHasMounted] = useState(false);

  // Passing tracking UTM params from v3 employer landing page to v2 employer sign up page
  let urlParams = document.cookie
    .split("; ")
    .find((row) => row.startsWith("params="))
    ?.toString()
    .replace("params=", "");

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Fragment>
      {hasMounted == true ? (
        <ContainerStyled>
          <ContentContainer>
            <FirstContentContainer>
              <ColumnContainer section="mission">
                <GridLinksContainer>
                  <CompanyTitleStyled>NaikGaji</CompanyTitleStyled>
                  <div>
                    <GridMission>
                      <MissionText marginBottom={"20px"}>
                        {copies.footerIntro}
                      </MissionText>
                    </GridMission>
                  </div>
                </GridLinksContainer>
              </ColumnContainer>

              <ColumnContainerMiddle>
                <ColumnLink>
                  <CompanyTitleStyled>NaikGaji</CompanyTitleStyled>
                  <GridLinks>
                    <NextLink href="/contact-us" legacyBehavior>
                      <LinkText id={"footer-contact-us-button"}>
                        {copies.contactUs}
                      </LinkText>
                    </NextLink>
                  </GridLinks>
                </ColumnLink>
                <ColumnLink>
                  <CompanyTitleStyled>jobseeker</CompanyTitleStyled>
                  <GridLinks>
                    <NextLink href="/jobs" legacyBehavior>
                      <LinkText id={"footer-jobs-button"}>
                        {copies.jobs}
                      </LinkText>
                    </NextLink>
                    <NextLink href="/companies" legacyBehavior>
                      <LinkText id={"footer-companies-button"}>
                        {copies.companies}
                      </LinkText>
                    </NextLink>
                  </GridLinks>
                </ColumnLink>
                <ColumnLink>
                  <CompanyTitleStyled>employer</CompanyTitleStyled>
                  <GridLinks>
                    <NextLink href="/employer" legacyBehavior>
                      <LinkText id={"footer-employer-button"}>
                        {copies.startHiring}
                      </LinkText>
                    </NextLink>
                  </GridLinks>
                </ColumnLink>
              </ColumnContainerMiddle>
            </FirstContentContainer>
          </ContentContainer>

          <CopyrightContainer>
            <GridIconsContainer>
              <GridIcons>
                <LinkIcon
                  id={"footer-tiktok-button"}
                  href="https://www.tiktok.com/@naikgaji_my"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faTiktok} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-twitter-button"}
                  href=" https://twitter.com/naikgaji_my"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faTwitter} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-instagram-button"}
                  href="https://www.instagram.com/naikgaji_my/"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faInstagram} />
                </LinkIcon>

                <LinkIcon
                  id={"footer-lemon8-button"}
                  href="https://www.lemon8-app.com/naikgaji_my"
                  rel="noopener"
                  target="_blank"
                  sx={{
                    position: "relative",
                    left: "-5px",
                    top: "-2px",
                    marginRight: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "45px",
                      height: "45px",
                      backgroundImage: `url("${assetDomain}/images/logo/lemon8-logo.svg")`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                    }}
                  />
                </LinkIcon>
              </GridIcons>
            </GridIconsContainer>

            <CopyrightText>
              {`${copies.copyright} 2014 - ${year} Agensi Pekerjaan Wobb Sdn. Bhd.`}
            </CopyrightText>

            <CopyrightContainerMiddle>
              <NextLink href="/community-guidelines" legacyBehavior>
                <LinkText id={"footer-community-guidelines-button"}>
                  {copies.communityGuidelines}
                </LinkText>
              </NextLink>
              <VerticalSeparator></VerticalSeparator>
              <NextLink href="/privacy-policy" legacyBehavior>
                <LinkText id={"footer-privacy-policy-button"}>
                  {copies.privacyPolicy}
                </LinkText>
              </NextLink>
              <VerticalSeparator></VerticalSeparator>
              <NextLink href="/terms-and-conditions" legacyBehavior>
                <LinkText id={"footer-terms-and-conditions-button"}>
                  {copies.termsAndCondition}
                </LinkText>
              </NextLink>
            </CopyrightContainerMiddle>
          </CopyrightContainer>
        </ContainerStyled>
      ) : null}
    </Fragment>
  );
}
